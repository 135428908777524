import { Flex, Image, Text, Spinner } from "@chakra-ui/react"
import { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import c1 from "./c1.jpg"
import Toast from "./toast"




export default function Contact() {
    const history = useHistory()
    return (
        <Flex minH="100vh" direction={["column", "row"]} w="100%">
            <Flex w={["100%", "50%"]} h={["30%", "100%"]} direction="column" backgroundImage={c1} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center">
                <Flex w="100%" h="100%" bg="rgba(0,0,0,0.6)" pl={["5", "15%"]} pr={["5", "5%" ]} justify="center" direction="column" color="#fff">
                    <Text mt={["12", "0"]} fontSize={["26px", "36px"]} data-aos="fade-down"><Text fontWeight="700" as="span">Reach </Text>Out</Text>
                    <Text mt="2" fontSize={["13px", "14px"]} data-aos="fade-up">
                        Need any form of health assistance? you can reach out to me directly or visit <Text as="span" cursor="pointer" textDecoration="underline" onClick={() => window.open("https://lho-life.com")}>"lho-life.com"</Text> for further assistance.
                    </Text>
                </Flex>
            </Flex>
            
            <Flex pb={["100px", "0"]} pt={["60px", "0"]} w={["100%", "50%"]} h={["70%", '100%']} pr={["5", "8%"]} pl={["5", "5%"]} direction="column" justify="center">
                <Text fontSize={["20px", "30px"]} data-aos="fade-down"><Text fontWeight="700" as="span">consult@</Text>loveforhealth.life</Text>
                <Flex w="100%" direction="column" data-aos="fade-up" mt="5">
                    <Flex align="center" mb="2" fontSize="18px"><i className="mdi mdi-account-outline"></i><Text fontSize="14px" ml="2">Fullname</Text></Flex>
                    <input style={{ width: "100%", border: "1px solid #09203F", padding: "15px 28px", borderRadius: "8px" }} placeholder="Enter your fullname" />
                    <Flex mt="5" align="center" mb="2" fontSize="18px"><i className="mdi mdi-email-outline"></i><Text fontSize="14px" ml="2">Email</Text></Flex>
                    <input style={{ width: "100%", border: "1px solid #09203F", padding: "15px 28px", borderRadius: "8px" }} placeholder="Enter your email" />
                    <Flex mt="5" align="center" mb="2" fontSize="18px"><i className="mdi mdi-chat-outline"></i><Text fontSize="14px" ml="2">Message</Text></Flex>
                    <textarea style={{ width: "100%", border: "1px solid #09203F", padding: "15px 28px", borderRadius: "8px", resize: "none", height: "120px" }} placeholder="Enter your message"></textarea>
                    <Flex mt={["8", "6"]} align="center">
                        <Text w={["30px", "50px"]} h="2px" bg="#09203F" mr="2"></Text>
                        <Flex padding={["12px 28px", "16px 34px"]} fontSize={["14px", "15px"]} cursor="pointer" borderRadius="30px" color="#fff" bg="#09203F" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => {
                            Toast("Conversation Opened", "success")
                            window.tidioChatApi.open()
                        }}>Send <Text ml="2">➜</Text></Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}