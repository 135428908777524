import { Flex, Image, Text, Spinner } from "@chakra-ui/react"
import { useHistory } from "react-router-dom"
import me3 from "./me3.jpg"
import me4 from "./me4.jpg"
import s4 from "./s4.jpg"
import r1 from "./r1.jpg"
import r2 from "./r2.jpg"
import r3 from "./r3.jpg"
import v1 from "./vid/v1.mp4";




export default function About() {
    const history = useHistory()
    return (
        <Flex w="100%" direction="column">
            <Flex w="100%" minH="100vh" backgroundImage={me3} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="top center">
                <Flex w="100%" h="100%" bg="rgba(0,0,0,0.6)" backdropFilter="blur(2px)" px={["5", "20%"]} direction="column" justify="center" pt="12" pb="5" color="#fff">      
                    <Text fontSize={["26px", "36px"]} data-aos="fade-down" fontWeight="700"><Text as="span" fontWeight="300">About </Text>Me</Text>
                    <Text fontSize={["26px", "36px"]} data-aos="fade-down" fontWeight="300"><Text fontWeight="700" as="span">Dr. Haroun </Text>Yusuf</Text>
                    <Text mt="4" pt="5" borderTop="1px solid #fff" fontSize={["14px", "17px"]} data-aos="fade-up">An experienced Medical Doctor with training in Harvard University, Adelphi University in New York and Franklin University in Ohio, all in the United States. He has also practiced medicine in Asia and Africa. Dr Haroun has masters in Public Health, masters in Business Administration and training to teach in medicine, at graduate level.</Text>

                    <Text mt="4" fontSize={["14px", "17px"]} data-aos="fade-up">In 2008, Dr Haroun published a journal: Love for Health Journal (Concept of Health, medicine, and treatment inpatient), from which he founded the <Text as="span" fontWeight="700">Love For Health Organization (LHO)</Text> that same year collaborating with a group of medical students from Lagos State University College of Medicine, Ikeja. Since then, LHO has spread to the rest of the world and grown into a team of health professionals committed to providing women with access to quality healthcare and advocating for their rights and quality access to improved healthcare services in their local communities 🌎</Text>

                    <Flex mt="8" align="center">
                        <Text bg="#fff" h="2px" w="40%" mr="3"></Text>
                        <Flex w="50px" h="55px" justify="center" align="center" color="#fff" border="2px solid #fff" fontSize="25px" cursor="pointer" onClick={() => {
                            const element = document.getElementById('arae2')
                            return window.scrollTo({
                                top: element ? element.offsetTop - 200 : 0,
                            })
                        }}><i className="mdi mdi-arrow-down"></i></Flex>
                    </Flex>
                </Flex>
            </Flex>



            <Flex py={["40px", "80px"]} bg="rgb(250,250,252)" px={["5", "8%"]} fontSize={["15px", "18px"]} direction={["column", "row"]}>
                <Flex align={["center", "baseline"]} w={["100%", "30%"]} pr={["0", "8" ]} mb={["5", "0"]} data-aos="fade-down">
                    <Text fontWeight="700" fontSize={["40px", "60px"]} mr="4">01</Text>
                    <Text>Free clinical service for Women</Text>
                </Flex>
                <Flex align={["center", "baseline"]} w={["100%", "40%"]} mb={["5", "0"]} px={["0", "12"]} borderRight={["none", "1px solid rgb(180,180,180)"]} borderLeft={["none", "1px solid rgb(180,180,180)"]} data-aos="fade-up">
                    <Text  fontWeight="700" fontSize={["40px", "60px"]} mr="4">02</Text>
                    <Text>Support for Women facing Gender-based violence</Text>
                </Flex>
                <Flex align={["center", "baseline"]} w={["100%", "30%"]} pl={["0", "8"]} data-aos="fade-down">
                    <Text  fontWeight="700" fontSize={["40px", "60px"]} mr="4">03</Text>
                    <Text>Raising awareness about women's healthcare issue</Text>
                </Flex>
            </Flex>

            <Flex py={["60px", "80px"]} px={["5", "8%"]} id="arae2" direction={["column", "row"]}>
                <Flex backgroundImage={me4} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="top center" w={["100%", "45%"]} borderRadius="8px" data-aos="fade-up" mb={["8", "0"]} h={["300px", "auto"]}></Flex>
                <Flex ml={["0", "5%"]} w={["100%", "50%"]} direction="column">
                    <Text fontSize={["20px", "36px"]} data-aos="fade-down"><Text fontWeight="700" as="span">More </Text>About Me</Text>
                    <Text mt="2" mb="8" fontSize={["13px", "14px"]} borderBottom="1px solid rgb(100,100,100)" pb="4" textAlign="left" data-aos="fade-up" color="rgb(102, 112, 133)">My Objective: To pursue the development of my intellectual ability in health and to take up challenges through selfless and dedicated services.</Text>

                    <Flex w="100%" direction="column">
                        <video loop controls muted playsInline autoPlay style={{ background: "#000", width: "100%", borderRadius: "10px", objectFit: "cover", boxShadow: "0px 0px 10px rgba(0,0,0,0.1)"}}><source src={v1} /></video>
                    </Flex>
                </Flex>
            </Flex>


            <Flex  pt={["30px", "80px"]} pb={["60px", "80px"]} px={["5", "8%"]} direction={["column", "row"]}>
                <Flex mr={["0", "8%"]} w={["100%", "47%"]} direction="column">
                    <Text fontSize={["24px", "36px"]} data-aos="fade-down"><Text fontWeight="700" as="span">Support for</Text> Women (LHO)</Text>
                    <Text mt="2" mb={["6", "8"]} fontSize={["13px", "14px"]} borderBottom="1px solid rgb(100,100,100)" pb="4" textAlign="left" data-aos="fade-up" color="rgb(102, 112, 133)">Offering a wide range of services to support women's health, including free clinical services, reproductive health services, prenatal care, and mental health services</Text>
                    
                    <Flex mb={["8", "0"]} mt="2" display={["flex", "none"]} h={["300px", "auto"]} backgroundImage={s4} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center" w={["100%", "45%"]} borderRadius="8px" data-aos="fade-up"></Flex>

                    
                    <Text data-aos="fade-up" fontSize={["13px", "15px"]}>Dr Haroun heads LHO which offers a wide range of services to support women's health, including free clinical services, reproductive health services, prenatal care, and mental health services. We provide reliable and quality health information, management tips, and support to women in need. We also engage in advocacy and sensitization and channel concerted efforts to fight against gender-based violence, including rape and discrimination. We believe that every woman deserves access to quality healthcare and support, regardless of their financial status.</Text>

                    <Flex mt="12" justify="space-between" fontSize={['13px', "15px"]}>
                        <Text data-aos="fade-down">
                            <Text fontSize={["40px", "60px"]} fontWeight="700" textAlign="center">350+</Text>
                            <Text mt="1" textAlign="center">outreach attained</Text>
                        </Text>
                        <Text data-aos="fade-up">
                            <Text fontSize={["40px", "60px"]} fontWeight="700" textAlign="center">1M</Text>
                            <Text mt="1" textAlign="center">Goal in 10 years</Text>
                        </Text>
                    </Flex>
                </Flex>
                <Flex mb={["8", "0"]} display={["none", "flex"]} h={["300px", "auto"]} backgroundImage={s4} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center" w={["100%", "45%"]} borderRadius="8px" data-aos="fade-up"></Flex>
            </Flex>



            <Flex py={["60px", "80px"]} px={["5", "10%"]} bgColor="rgba(9, 32, 63, .03)" justify="space-between" align="center" direction={["column", "row"]}>
                {
                    [{dp: r2},{dp: r1},{dp: r3},].map((item, index) => (
                        <Flex key={index} backgroundImage={item.dp} backgroundSize="100%" backgroundRepeat="no-repeat" backgroundPosition="top center" w={["100%", index === 1 ? "40%" : "28%"]} h={["350px", index === 1 ? "500px" : "400px"]} boxShadow={["none", index === 1 ? "0px 0px 15px rgba(0,0,0,0.2)" : "none"]} _hover={{ backgroundSize: "120%" }} transition="300ms ease-in-out" mb={["5", "0"]}>
                            <Flex w="100%" h="100%" bg="rgba(0,0,0,0.45)" px="4" py="4">
                                <Flex py="4" mt="auto" w="100%" px="4" bg="rgba(255,255,255,.21)" color="#fff" direction="column">
                                    <Text fontWeight="700">{index === 0 ? "Reach Out" : index === 1 ? "LHO" : "Articles"}</Text>
                                    <Text>{index === 0 ? "Get instant assistant by contacting our live chat" : index === 1 ? "See what we are up to at love for health organization" : "Articles and health publications"}</Text>
                                    <Flex mt="5" align="center">
                                        <Text bg="#fff" h="2px" w="40%" mr="3"></Text>
                                        <Flex w="40px" h="45px" justify="center" align="center" color="#fff" border="2px solid #fff" fontSize="20px" cursor="pointer" onClick={() => {
                                            setTimeout(function() {
                                                index === 0 ? history.push("/contact") : index === 1 ? window.open("https://lho-life.com") : history.push("/articles")
                                            }, 800)
                                        }}><i className="mdi mdi-arrow-right"></i></Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    ))
                }
            </Flex>
        </Flex>
    )
}