import { Flex, Image, Text, Spinner } from "@chakra-ui/react"
import { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"

export default function Nav() {
    const history = useHistory()
    const location = useLocation()
    const [menu, setMenu] = useState(false)
    return (
        <>
            <Flex mr={["5", "8%"]} data-aos="fade-down" align="center" justify="center" position="fixed" top="0" right="0" bg="rgba(255,255,255,.8)" backdropFilter="blur(12px)" h={["40px", "55px"]} w={["42px", "50px"]} fontSize={["18px", "25px"]} zIndex="100" cursor="pointer" onClick={() => setMenu(true)}><i className="mdi mdi-menu" cursor="pointer"></i></Flex>


            
            <Flex zIndex="100" ml={["5", "8%"]} data-aos="fade-down" position="fixed" top="0" left="0"><Flex align="center" justify="center" bg="rgb(225, 225, 225, .8)" backdropFilter="blur(12px)" borderTop="none" h={["40px", "55px"]} w={["42px", "50px"]} fontSize={["18px", "25px"]} cursor="pointer" onClick={() => location.pathname.includes("about") ? history.push("/") : history.push("/about")}><i className={location.pathname.includes("about") ? "mdi mdi-home-variant" : "mdi mdi-human-handsdown"} cursor="pointer"></i></Flex>

            <Flex ml={["4", "5"]} align="center" justify="center" bg="rgba(225, 225, 225, .8)" backdropFilter="blur(12px)" borderTop="none" h={["40px", "55px"]} w={["42px", "50px"]} fontSize={["18px", "25px"]} cursor="pointer" onClick={() => location.pathname.includes("articles") ? history.push("/") : history.push("/articles")}><i className={location.pathname.includes("articles") ? "mdi mdi-home-variant" : "mdi mdi-information-outline"} cursor="pointer"></i></Flex></Flex>


            {
            menu && 
            <Flex w="100%" h="100%" position="fixed" top="0" left="0" zIndex="1000000" bg="rgba(0,0,0,0.7)" backdropFilter="blur(12px)" justify="flex-end">
                <Flex w={["80%", "600px"]} h="100%" bg="#fff" direction="column" px={["5", "12"]} py={["12", "6"]} data-aos="fade-left">
                    <Flex bg="#fff" ml="auto" border="2px solid #000" justify="center" align="center" color="#000" fontSize={["20px", "30px"]} cursor="pointer" borderRadius="100%" h={["40px", "50px"]} w={["41px", "51px"]} zIndex="10" onClick={() => setMenu(false)}><i className="mdi mdi-close"></i></Flex>
                    <Flex mt={["-12", "2"]} justify="center" flex="1" direction="column">
                        <Text fontSize={["25px", "40px"]}><Text fontWeight="700" as="span">Dr Haroun</Text> Yusuf</Text>
                        <Text mt="3" mb="4" fontSize="14px" color="rgb(102, 112, 133)">Quick Links</Text>
                        {
                            ["Home", "About me", "Contact me", "Articles", "Tweets"].map((item, index) => (
                                <Flex key={index} _hover={{ zoom: "130%", color: "#09203F", transform: "translate(0,0)" }} transition="300ms ease-in-out" cursor="pointer" fontWeight="500" fontSize={["17px", "24px"]} pt="4" pb="3" borderBottom="1px solid rgb(202, 202, 205)" onClick={() => {
                                    history.push(index === 0 ? "/" : index === 1 ? "/about" : index === 2 ? "/contact" : index === 3 ? "/articles" : "/social")
                                    setMenu(false)
                                }}>{item}</Flex>
                            ))
                        }
                    </Flex>
                </Flex>
            </Flex>
            }
        </>
    )
}