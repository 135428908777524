import { useState, useEffect, useRef } from 'react';
import { 
	Flex,
	Image,
	Text,
  	ChakraProvider,
	Spinner,
} from "@chakra-ui/react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
  } from "react-router-dom";
import ScrollToTop from "./sc";
import "./App.scss";
import "./mdi/css/materialdesignicons.css";
import Aos from "aos";
import "aos/dist/aos.css";
import Landing from './landing';
import Nav from './nav';
import Footer from './footer';
import About from "./about"
import Contact from './contact';
import Articles from './articles';
import TweetsArticles from './tw';
import cover1 from "./cover1.jpg"
import cover2 from "./cover2.jpg"
import cover3 from "./cover3.jpg"
import cover1Mob from "./cover1Mob.jpg"
import cover2Mob from "./cover2Mob.jpg"
import cover3Mob from "./cover3Mob.jpg"

function App() {
	useEffect(() => {
		Aos.init({ duration: 800 })
	}, [])

	const [iniLoad, setIniLoad] = useState(true)

	useEffect(() => {
		if(iniLoad === true) {
			setTimeout(function() {
				var a = document.getElementById('area1')
				var b = document.getElementById('area2')
				a.innerHTML = "Caring"
				b.innerHTML = "Women"
			}, 2000)
			setTimeout(function() {
				var a = document.getElementById('area1')
				var b = document.getElementById('area2')
				a.innerHTML = "Support"
				b.innerHTML = "Girls"
			}, 4000)
			setTimeout(function() {
				setIniLoad(false)
			}, 6000)
		}
	}, [])


	return (
		<ChakraProvider>
		<Flex w="100%" fontSize="15px">
			{
				iniLoad ?
				<Flex w="100%" h="100vh" color="#fff" bg="#000" justify="center" align="center" fontSize={["40px", "60px"]} fontWeight="700" direction={["column", "row"]}>
					<Text className="updown" id="area1" mt={["-12", "0"]}>Love</Text>
					<Text mx={["8", "12"]} fontWeight="400">For</Text>
					<Text className="updown" id="area2">Health</Text>
					<Image src={cover1} w="0" h="0" /><Image src={cover1Mob} w="0" h="0" /><Image src={cover2} w="0" h="0" /><Image src={cover2Mob} w="0" h="0" /><Image src={cover3} w="0" h="0" /><Image src={cover3Mob} w="0" h="0" />
				</Flex>
				:
				<Router>				
					<ScrollToTop>
						<Flex w="100%" direction="column" color="#000">
							<Nav />
							<Switch>
								<Route exact path="/"><Landing /></Route>
								<Route path="/about"><About /></Route>
								<Route path="/contact"><Contact /></Route>
								<Route path="/articles"><Articles /></Route>
								<Route path="/social"><TweetsArticles /></Route>
								<Route><Landing /></Route>
							</Switch>
							<Footer />
						</Flex>
					</ScrollToTop>
				</Router>
			}
		</Flex>
		</ChakraProvider>
	)
}

export default App;
